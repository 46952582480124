import { render, staticRenderFns } from "./App.vue?vue&type=template&id=00ed077a&"
import script from "./App.ts?vue&type=script&lang=ts&"
export * from "./App.ts?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&lang=css&"
import style1 from "vuetify/dist/vuetify.min.css?vue&type=style&index=1&lang=css&"
import style2 from "./PatreonButton.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarSideIcon } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VApp,VBtn,VCard,VContainer,VDialog,VDivider,VFlex,VIcon,VLayout,VTab,VTabItem,VTabs,VToolbar,VToolbarSideIcon,VToolbarTitle})
